<template>
  <div class="page article">
    <b-container>
      <b-row>
        <b-col cols="9">
          <div class="panel">
            <b-overlay :show="articleLoading" spinner-variant="primary">
              <k-basic-ckeditor
                v-model="tartalom"
                ref="basicCkeditor"
              ></k-basic-ckeditor>
            </b-overlay>
          </div>
        </b-col>
        <b-col cols="3">
          <div class="panel sticky-right-panel">
            <b-overlay :show="articleLoading" spinner-variant="primary">
              <k-edit-article
                :articleFromServer="article"
                :tartalom="tartalom"
                @article-saved="VerzioReset()"
                @form-changed="formVerzio++"
              ></k-edit-article>
            </b-overlay>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import { NotificationFunctions } from '../functions/NotificationFunctions';
import { hirService } from '../services/HirService';
export default {
  name: 'article-page',
  data() {
    return {
      articleLoading: false,
      tartalom: '',
      article: null,
      tartalomVerzio: 0,
      formVerzio: 0,
    };
  },
  mounted() {},
  created() {},
  async beforeRouteLeave(to, from, next) {
    if (this.tartalomVerzio > 1 || this.formVerzio > 1) {
      let accepted = await NotificationFunctions.ConfirmModal(
        'Figyelmeztetés',
        'A módosítások még nem kerültek mentésre. Biztosan szeretné elhagyni az oldalt?',
        {
          okTitle: 'Tovább',
        }
      );
      if (accepted) {
        next();
      }
    } else {
      next();
    }
  },
  methods: {
    VerzioReset() {
      this.formVerzio = 1;
      this.tartalomVerzio = 1;
    },
    async GetArticle() {
      this.articleLoading = true;
      try {
        let result = await hirService.EditHir({ id: this.articleId });

        this.tartalom = result.Tartalom;
        delete result.Tartalom;
        this.article = result;
        await this.$nextTick();
        this.VerzioReset();
      } catch (error) {
        NotificationFunctions.AjaxError({ error });
      }
      this.articleLoading = false;
    },
  },
  computed: {
    articleId() {
      return this.$route.params.id ?? null;
    },
  },
  watch: {
    articleId: {
      handler: function() {
        this.GetArticle();
      },
      immediate: true,
    },
    tartalom: {
      handler: function() {
        this.tartalomVerzio++;
      },
    },
  },
  components: {},
};
</script>
